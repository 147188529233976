import React, {useState, useEffect, useRef} from 'react';

import Gallery360 from '../components/Gallery360';
import Selector from '../components/Selector';

import useColorsData from '../hooks/useColorsData';

const ColorSelector = ({title}) => {

    const { getLoading } = useColorsData();

    const loading = getLoading();

    return (
        <>
        {!loading ? 
            <div className="main-container">
                <h1>{title}</h1>
                <div className="main-container small">
                    <div className="selector-colors-container">
                        <Selector />
                        <Gallery360 />
                    </div>
                </div>
            </div>
        : 
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
        }
        </>

    );
}

export default ColorSelector;
