import React from 'react';

import useColorsData from '../hooks/useColorsData';

const Selector = () => {

    const { getColors, getSelectedColor, setSelectedColor } = useColorsData();

    const colors = getColors();
    const selectedColor = getSelectedColor();

    const handleChangeColor = color => {
        setSelectedColor(color);
    }

    return (
            <div className="colors-container">
                <div className="colors-title">Elige un color</div>
                <div className="colors">
                    {colors.map(color => (
                        <div className="color-item" key={`color-${color.id}`}>
                            <button className={selectedColor.id === color.id ? "active" : ""} onClick={() => handleChangeColor(color)}>
                                <span className="circle" style={{backgroundColor: color.hexcode}}></span>
                            </button>
                            <small>{color.name}</small>
                        </div>
                    ))}
                </div>
            </div>
    )
}

export default Selector;