import React, { useState, useEffect } from "react";

import Axios from 'axios';

const initialContext = {
    colors: [],
    selectedColor: null,
    loading: true
}

const ColorsContext = React.createContext([{}, () => {}]);

const ColorsProvider = ({versionId, children}) => {
    const [state, setState] = useState(initialContext);

    useEffect(() => {
        Axios.get(`/api/vehicles/360_galleries/${versionId}`)
        .then(response => {
            setState({
                colors: response.data.colors,
                selectedColor: response.data.colors[0],
            });
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setState(prevState => ({
                ...prevState,
                loading: false
            }));
        });
    }, [])

    return <ColorsContext.Provider value={[state, setState]}>{children}</ColorsContext.Provider>;
};

export { ColorsContext, ColorsProvider, initialContext };
