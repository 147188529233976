import { useContext } from "react";
import { ColorsContext } from "../context";

const useColorsData = () => {

  const [state, setState] = useContext(ColorsContext);

  const getColors = () => {
    return state.colors ? state.colors : null;
  }

  const getLoading = () => {
    return state.loading;
  }

  const getSelectedColor = () => {
    return state.selectedColor ? state.selectedColor : null;
  }

  const setSelectedColor = color => {
    setState(prevState => ({
      ...prevState,
      selectedColor: color
    }));
  }


  return {
    getColors,
    getLoading,
    getSelectedColor,
    setSelectedColor
  }

}


export default useColorsData;
