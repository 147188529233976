import React from 'react';
import ColorSelector from './views/ColorSelector';
import { ColorsProvider } from "./context";

const App = ({versionId, title}) => {
    return (
        <ColorsProvider versionId={versionId}>
            <ColorSelector title={title} />
        </ColorsProvider>
    );
}

export default App;